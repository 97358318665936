<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <base-button type="success" @click="addNew" class="my--5">
                <i class="fas fa-plus"></i> {{$t('general.addnew')}}</base-button>
        </b-card>
        <b-card no-body v-if="branches" class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="branches" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')" :row-class-name="tableRowClassName">
                <el-table-column :label="$t('general.name')" prop="name" min-width="160">
                    <template v-slot="{row}">
                        <span v-for="(item, key) in row.name" :key="key" v-if="key=='tr'">{{item}}</span>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('general.status')" prop="status" min-width="140">
                    <template v-slot="{row}">
                        <badge class="badge-dot mr-4" type="">
                            <i :class="`bg-${status[row.status]}`"></i>
                            <span class="status" :class="`text-${status[row.status]}`">{{row.status?$t('general.active'):$t('general.passive')}}</span>
                        </badge>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" min-width="170" sortable>
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="handleEdit(scope.row)">{{$t('general.edit')}}</base-button>
                        <base-button type="danger" size="sm" @click="handleDelete(scope.row.uuid)">{{$t('general.delete')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose">
            <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator">
                <h6 slot="header" class="modal-title">{{modal.data.uuid ? $t('branch.edit') : $t('branch.add')}}</h6>
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)" v-loading="modal.loading">
                    <hr>
                    <label class="form-control-label">{{$t('general.status')}}</label>
                    <b-form-checkbox v-model="modal.data.status" class="switchCheck" name="check-button" switch>
                        {{modal.data.status ? $t('general.active') : $t('general.passive')}}
                    </b-form-checkbox>
                    <b-tabs content-class="mt-3">
                        <b-tab :active="key==0" :title="lang.toUpperCase()" v-for="(lang,key) in setting.lang" :key="key">
                            <base-input type="text" :name="'name'+lang" :nameAs="lang.toUpperCase()+' - '+$t('general.name')" :label="$t('general.name')+'*'" :placeholder="$t('general.name')" v-model="modal.data.name[lang]" required>
                            </base-input>
                            <base-input type="text" :name="'desc'+lang" :nameAs="lang.toUpperCase()+' - '+$t('general.desc')" :label="$t('general.desc')" :placeholder="$t('general.desc')" v-model="modal.data.desc[lang]">
                            </base-input>
                        </b-tab>
                    </b-tabs>
                    <div class="form-check mt--2 mb-2">
                        <input class="form-check-input" type="checkbox" id="online" v-model="modal.data.online">
                        <label class="form-check-label" for="online">{{$t('branch.online_interview')}}
                        </label>
                    </div>
                    <b-alert show variant="warning" v-if="invalid">
                        <strong>{{$t('warning.fill_title')}} : </strong> {{$t('warning.fill')}}
                    </b-alert>
                    <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid">{{$t('save')}}</base-button>
                    <base-button type="link text-danger" class="ml-auto float-right" @click="modal.status = false" :disabled="modal.loading">{{$t('close')}}</base-button>
                </b-form>
            </validation-observer>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { BRANCH_REQUEST, BRANCH_UPDATE, BRANCH_ADD, BRANCH_DELETE, BRANCH_LIST_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;
let emptyModal = {
    status: true,
    name: {},
    desc: {}
};
export default {
    computed: {
        ...mapGetters({ branches: 'getBranches', total: 'getBranchTotal', pages: 'getBranchPages', setting: 'generalSetting' })
    },
    data() {
        return {
            modal: {
                loading: false,
                status: false,
                data: _.cloneDeep(emptyModal)
            },
            loading: false,
            status: {
                true: 'success',
                false: 'danger'
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {}
        };
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            if (!row.status) {
                return 'table-danger';
            }
            return '';
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData(true);
        },
        handleEdit(row) {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
            self.modal.data = _.merge(self.modal.data, row);
        },
        handleDelete(uuid) {
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(BRANCH_DELETE, uuid)
                        .then(resp => {
                            self.loading = false;
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        modalClose() {
            self.modal.loading = false;
            self.modal.status = false;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        addNew() {
            self.modal.loading = false;
            self.modal.status = true;
            self.modal.data = _.cloneDeep(emptyModal);
        },
        onSubmit() {
            if (self.modal.data.uuid != undefined) {
                self.onUpdate();
            } else {
                self.onAdd();
            }
        },
        onAdd() {
            let branch = _.cloneDeep(self.modal.data);

            self.modal.loading = true;
            self.$store
                .dispatch(BRANCH_ADD, branch)
                .then(resp => {
                    self.modalClose();
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        onUpdate() {
            let uuid = self.modal.data.uuid;
            let branch = _.pickBy(self.modal.data, (item, key) => {
                return key != 'uuid';
            });

            self.modal.loading = true;
            self.$store
                .dispatch(BRANCH_UPDATE, { uuid, branch })
                .then(resp => {
                    self.modal.loading = false;
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
        getData(onlyBranch = false) {
            self.loading = true;
            self.$store
                .dispatch(BRANCH_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
            if (!onlyBranch) {
                self.$store.dispatch(BRANCH_LIST_REQUEST);
            }
        }
    },
    created() {
        self = this;
        self.getData(true);
    }
};
</script>
